import { message } from 'antd';
import { Messages } from 'apis/APISDK';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { handleApiError } from 'redux/error';
import actions from './actions';

function* LIST_MESSAGES(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { messageLoading: true } });
  try {
    const { data, success, status } = yield call(
      Messages.listMessages,
      payload
    );
    if (success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          messageList: data.results,
          messageLoading: false,
        },
      });
    } else {
      yield handleApiError(status);
      yield put({
        type: actions.SET_STATE,
        payload: { messageLoading: false },
      });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: actions.SET_STATE, payload: { messageLoading: false } });
  }
}

function* CREATE_MESSAGE(payloads) {
  const { payload: reqPayload, params } = payloads;
  yield put({ type: actions.SET_STATE, payload: { messageSubmitting: true } });
  try {
    const {
      success,
      message: resMessage,
      data,
    } = yield call(
      Messages.createMessage,
      {
        payload: { ...reqPayload },
      },
      params
    );
    if (success) {
      if (data) {
        const { userData } = yield select((state) => state.user);

        const createdMessage = {
          ...data,
          user: {
            id: data.user,
            name: userData?.name,
          },
        };
        yield put({
          type: actions.UPDATE_LAST_CREATED_MESSAGE,
          payload: { data: createdMessage, params },
        });
      } else {
        yield put({
          type: actions.INVOKE_LIST_MESSAGES,
          payload: { job: reqPayload.job, candidate: payloads.applicantId },
        });
      }
      message.success(resMessage);
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    message.error(error.toString());
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { messageSubmitting: false },
    });
  }
}

function* SEND_BULK_MESSAGE(payloads) {
  const { payload } = payloads;
  yield put({ type: actions.SET_STATE, payload: { messageSubmitting: true } });
  try {
    const { success, message: resMessage } = yield call(
      Messages.sendBulkMessage,
      {
        payload,
      }
    );
    if (success) {
      message.success(resMessage);
    } else {
      message.error(resMessage);
    }
  } catch (error) {
    message.error(error.toString());
  } finally {
    yield put({
      type: actions.SET_STATE,
      payload: { messageSubmitting: false },
    });
  }
}

function* LIST_ALL_MESSAGES({ payload }) {
  if (payload.page === 1) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        allMessageLoading: true,
        allMessageList: [],
      },
    });
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: { loading: true },
    });
  }
  try {
    const {
      data,
      success,
      status,
      message: resMessage,
    } = yield call(Messages.listAllMessages, payload);
    if (success) {
      if (data.page > 1) {
        yield put({
          type: actions.SET_ADD_ALL_MESSAGE_LIST,
          payload: {
            allMessageList: data.results,
            allMessageCurrentPage: data.page,
            allMessageTotalPages: data.totalPages,
            allMessagesTotalResults: data.totalResults,
          },
        });
      } else {
        yield put({
          type: actions.SET_STATE,
          payload: {
            allMessageList: data.results,
            allMessageTotalPages: data.totalPages,
            allMessagesTotalResults: data.totalResults,
            allMessageLimit: data.limit,
            allMessageCurrentPage: data.page,
          },
        });
      }
    } else {
      yield handleApiError(status);
      message.error(resMessage);
    }
  } catch (e) {
    console.error(e);
    yield put({
      type: actions.SET_STATE,
      payload: { allMessageLoading: false },
    });
  }
}

function* GET_MESSAGE(payloads) {
  const { payload } = payloads;
  yield put({
    type: actions.SET_STATE,
    payload: { formLoading: true },
  });
  try {
    const {
      success,
      data,
      status,
      message: resMessage,
    } = yield call(Messages.getMessage, payload);
    if (success && data) {
      yield put({
        type: actions.SET_STATE,
        payload: { formLoading: false, currentMessage: data },
      });
    } else {
      yield handleApiError(status);
      message.error(resMessage);
      yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
    }
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SET_STATE, payload: { formLoading: false } });
  }
}

export default function* contentsSaga() {
  yield all([
    takeEvery(actions.INVOKE_LIST_MESSAGES, LIST_MESSAGES),
    takeEvery(actions.INVOKE_CREATE_MESSAGE, CREATE_MESSAGE),
    takeEvery(actions.INVOKE_SEND_BULK_MESSAGE, SEND_BULK_MESSAGE),
    takeEvery(actions.INVOKE_LIST_ALL_MESSAGES, LIST_ALL_MESSAGES),
    takeEvery(actions.INVOKE_GET_MESSAGE, GET_MESSAGE),
  ]);
}
