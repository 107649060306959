import actions from './actions';

const initialState = {
  loading: false,
  formLoading: false,
  formSubmitting: false,
  messageList: null,
  messageLoading: false,
  messageSubmitting: false,
  lastCreatedMessage: null,

  allMessageList: [],
  allMessageLoading: false,
  allMessageCurrentPage: 1,
  allMessageTotalPages: 0,
  allMessagesTotalResults: 0,
  allMessageLimit: 10,

  currentMessage: null,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };

    case actions.UPDATE_LAST_CREATED_MESSAGE: {
      const { data, params } = action.payload || {};
      const replyTo = params?.replyTo;

      if (!data) {
        return { ...state };
      }

      let newMessageList = Array.isArray(state.messageList)
        ? [...state.messageList]
        : [];

      // incase its a reply, it will be updated in current message
      if (
        replyTo &&
        state.currentMessage &&
        state.currentMessage.id === replyTo
      ) {
        const updatedReplies = [...(state.currentMessage?.replies || []), data];
        return {
          ...state,
          currentMessage: {
            ...state.currentMessage,
            replies: updatedReplies,
          },
          lastCreatedMessage: data,
        };
      }
      newMessageList = [...newMessageList, data];

      return {
        ...state,
        messageList: newMessageList,
        lastCreatedMessage: data,
      };
    }

    case actions.SET_ADD_ALL_MESSAGE_LIST: {
      if (state.allMessageCurrentPage === action.payload.allMessageCurrentPage)
        return { ...state };
      const { allMessageList } = state;
      allMessageList.push(...action.payload.allMessageList);

      return { ...state, ...action.payload, allMessageList };
    }

    default:
      return state;
  }
}
