export default {
  SET_STATE: 'messages/SET_STATE',
  SET_ADD_ALL_MESSAGE_LIST: 'messages/SET_ADD_ALL_MESSAGE_LIST',

  INVOKE_LIST_MESSAGES: 'messages/INVOKE_LIST_MESSAGES',
  INVOKE_CREATE_MESSAGE: 'messages/INVOKE_CREATE_MESSAGE',
  INVOKE_SEND_BULK_MESSAGE: 'messages/INVOKE_SEND_BULK_MESSAGE',
  UPDATE_LAST_CREATED_MESSAGE: 'messages/UPDATE_LAST_CREATED_MESSAGE',

  INVOKE_LIST_ALL_MESSAGES: 'messages/INVOKE_LIST_ALL_MESSAGES',

  INVOKE_GET_MESSAGE: 'messages/INVOKE_GET_MESSAGE',
};
